import { useEffect, useState } from 'react';

import cx from 'classnames';

import { type FragmentType, getFragmentData } from '~/contentful/graphql';
import { flattenCollection } from '~/contentful/utils/collection';
import { useBreakpoint } from '~/ui/components/grid/useBreakpoint';
import { Breakpoint } from '~/ui/styles/grid';
import { TEST_ID } from '~/v1/constants/testId';

import { Idea } from './idea';
import { IdeasMarquee, type IdeasMarqueeProps } from './marquee';
import { Ideas_QueryFragment } from './query';
import styles from '../footer.module.scss';

function splitArray<T>(arr: T[], n: number): T[][] {
  const result: T[][] = [];
  for (let i = 0; i < n; i++) {
    result.push([]);
  }
  for (let i = 0; i < arr.length; i++) {
    result[i % n].push(arr[i]);
  }
  return result;
}

export interface IdeasProps {
  ideas: FragmentType<typeof Ideas_QueryFragment>;
}

export function AnimatedIdeas({ ideas: parentIdeas }: IdeasProps): React.ReactElement {
  const [marqueeRows, setMarqueeRows] = useState<
    Pick<IdeasMarqueeProps, 'ideas' | 'speed' | 'direction'>[]
  >([]);

  useEffect(() => {
    const ideas = flattenCollection(
      getFragmentData(Ideas_QueryFragment, parentIdeas).ideaCollection,
    );
    const splitIdeas = splitArray(ideas, 3);
    setMarqueeRows([
      {
        ideas: splitIdeas[2],
        speed: 12,
        direction: 'normal',
      },
      {
        ideas: splitIdeas[1],
        speed: 16,
        direction: 'reverse',
      },
      {
        ideas: splitIdeas[0],
        speed: 24,
        direction: 'normal',
      },
    ]);
  }, [parentIdeas]);

  return (
    <div className={styles.ideas} data-test-id={TEST_ID.FOOTER.IDEAS}>
      {marqueeRows.map(({ ideas, speed, direction }, index) => (
        <IdeasMarquee ideas={ideas} speed={speed} direction={direction} key={index} />
      ))}
    </div>
  );
}

export function StaticIdeas({ ideas: parentIdeas }: IdeasProps): React.ReactElement {
  const isLargeBreakpoint = useBreakpoint(Breakpoint.LG);

  const ideas = getFragmentData(Ideas_QueryFragment, parentIdeas).ideaCollection?.items ?? [];

  return (
    <ul className={cx(styles.ideas, styles.staticIdeas)} data-test-id={TEST_ID.FOOTER.IDEAS}>
      {ideas.map(
        (idea, index) =>
          idea && (
            <li key={index}>
              <Idea idea={idea} showSeparator={isLargeBreakpoint} />
            </li>
          ),
      )}
    </ul>
  );
}
