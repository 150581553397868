import { graphql } from '~/contentful/graphql';

export const SecondaryNavigationSearch_QueryFragment = graphql(`
  fragment SecondaryNavigationSearch_QueryFragment on Query {
    searchPageCollection {
      items {
        featuredSearches
      }
    }
  }
`);
