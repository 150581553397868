import { graphql } from '~/contentful/graphql';

export const Idea_IdeaFragment = graphql(`
  fragment Idea_IdeaFragment on Idea {
    name
    slug
    navigationImagePortrait {
      ...Image_AssetFragment
    }
    ...InternalLink_EntryFragment
  }
`);
