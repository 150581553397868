'use client';

import { usePathname } from 'next/navigation';
import { Suspense, useEffect, useId, useState } from 'react';

import FocusTrap from 'focus-trap-react';

import { type FragmentType, getFragmentData } from '~/contentful/graphql';
import { useBreakpoint } from '~/ui/components/grid/useBreakpoint';
import { Breakpoint } from '~/ui/styles/grid';
import { navigationOpenedVar } from '~/v1/components/navigation/navigation';
import { TEST_ID } from '~/v1/constants/testId';
import { useScrollLock } from '~/v1/hooks/useScrollLock';
import { useSticky } from '~/v1/hooks/useSticky';

import styles from './navigation.module.scss';
import { PrimaryNavigation } from './primary';
import { Navigation_QueryFragment } from './query';
import { SecondaryNavigation } from './secondary';

export type NavigationItem = 'ideas' | 'pages' | 'search';
export type AnimationDuration = 'default' | 'instant';

// most keyboard animations are faster (probably around 400ms), but to be on the safe side we set it to 1s
const KEYBOARD_ANIMATION_DURATION = 1000;

export interface NavigationProps {
  navigation: FragmentType<typeof Navigation_QueryFragment>;
  desktopPrimaryContent?: React.ReactNode;
  showDesktopPrimaryContent?: boolean;
}

export function Navigation({
  navigation: data,
  desktopPrimaryContent,
  showDesktopPrimaryContent,
}: NavigationProps) {
  const [activeNavigation, setActiveNavigation] = useState<NavigationItem>();
  const [primaryIsExpanded, setPrimaryIsExpanded] = useState(false);
  const breakpoint = useBreakpoint();
  const pathname = usePathname();
  const secondaryNavId = useId();

  const isSecondaryExpanded = !!activeNavigation;
  const isSmallOrMedium = breakpoint === Breakpoint.SM || breakpoint === Breakpoint.MD;

  useEffect(() => {
    if (activeNavigation) {
      setPrimaryIsExpanded(true);
    }
  }, [activeNavigation]);

  useEffect(() => {
    navigationOpenedVar(isSecondaryExpanded);
  }, [isSecondaryExpanded]);

  // This enables homepage nav scroll animations
  useEffect(() => {
    if (pathname === '/') {
      document.documentElement.style.setProperty('--scroll-progress', '0');
    } else {
      document.documentElement.style.setProperty('--scroll-progress', '1');
    }
  }, [pathname]);

  const { ref, isSticky } = useSticky<HTMLDivElement>();
  const shouldLockScroll = isSecondaryExpanded || (isSmallOrMedium && primaryIsExpanded);

  const { ref: scrollRef } = useScrollLock<HTMLDivElement>(
    shouldLockScroll,
    isSecondaryExpanded.toString(),
  );

  const navigationData = getFragmentData(Navigation_QueryFragment, data);

  const setNavigationPadding = () => {
    if (scrollRef?.current && scrollRef.current.id === secondaryNavId && visualViewport) {
      scrollRef.current.style.paddingBottom = `${window.innerHeight - visualViewport.height}px`;
    }
  };

  const handleSearchFocusChange = () => {
    setTimeout(setNavigationPadding, KEYBOARD_ANIMATION_DURATION);
  };

  return (
    // TODO: Maybe create "custom" FocusTrap component (using this one) with option to add aria-hidden to layout.
    //   This could be use for all modal-like components (which are rendered with usePortal) so screen reader behaves same as focus.
    //   In that case we would use usePortal() here
    <FocusTrap
      active={isSecondaryExpanded && !isSmallOrMedium}
      focusTrapOptions={{ initialFocus: false }}
    >
      <div className={styles.navigationWrapper} data-test-id={TEST_ID.NAVIGATION} ref={ref}>
        <PrimaryNavigation
          setActiveNavigation={setActiveNavigation}
          activeNavigation={activeNavigation}
          primaryIsExpanded={primaryIsExpanded}
          setPrimaryIsExpanded={setPrimaryIsExpanded}
          isSticky={isSmallOrMedium && isSticky}
          scrollLockRef={!isSecondaryExpanded ? scrollRef : undefined}
          onSearchClick={handleSearchFocusChange}
          desktopContent={desktopPrimaryContent}
          showDesktopContent={showDesktopPrimaryContent}
        />
        {/* SecondaryNavigation uses useSearchParams which disables static rendering up to the nearest suspense boundary */}
        <Suspense>
          <SecondaryNavigation
            id={secondaryNavId}
            data={navigationData}
            setActivePrimaryNavigation={setActiveNavigation}
            activePrimaryNavigation={activeNavigation}
            setPrimaryIsExpanded={setPrimaryIsExpanded}
            scrollLockRef={isSecondaryExpanded ? scrollRef : undefined}
            onSearchFocusChange={handleSearchFocusChange}
          />
        </Suspense>
      </div>
    </FocusTrap>
  );
}
