import { type FragmentType, getFragmentData } from '~/contentful/graphql';
import LineDiagonal from '~/ui/assets/icons/line-diagonal.svg';
import { Event, trackEvent } from '~/ui/components/analytics';
import { Image } from '~/ui/modules/image';
import { getInternalLinkFromFragment } from '~/ui/systems/internalLink';
import { Link } from '~/v1/components/link/link';
import { Route } from '~/v1/constants/route';
import styles from '~/v1/modules/footer/ideas/idea/idea.module.scss';

import { Idea_IdeaFragment } from './query';

type AvailableLinkProps = Omit<React.ComponentProps<typeof Link>, 'href'>;

export interface IdeaProps extends AvailableLinkProps {
  idea: FragmentType<typeof Idea_IdeaFragment>;
  showSeparator?: boolean;
}

export function Idea({ idea: parentIdea, showSeparator, onClick, ...linkProps }: IdeaProps) {
  const idea = getFragmentData(Idea_IdeaFragment, parentIdea);

  return (
    <Link
      href={getInternalLinkFromFragment(idea)}
      className={styles.ideaLink}
      onClick={e => {
        trackEvent(Event.NAVIGATION_FOOTER, {
          navigationItem: idea.name ?? undefined,
          navigationLink: `${Route.IDEA}/${idea.slug}`,
        });
        onClick?.(e);
      }}
      {...linkProps}
    >
      <div className={styles.ideaImage}>
        {idea.navigationImagePortrait && (
          <Image
            image={idea.navigationImagePortrait}
            aspectRatio="2/3"
            width={48}
            height={64}
            fill={false}
          />
        )}
      </div>
      {idea.name}
      {showSeparator && <LineDiagonal aria-hidden />}
    </Link>
  );
}
