'use client';

import cx from 'classnames';
import { useReducedMotion } from 'framer-motion';

import { type FragmentType } from '~/contentful/graphql';
import typographyStyles from '~/ui/styles/typography.module.scss';
import { useHasCursor } from '~/v1/hooks/useHasCursor';
import styles from '~/v1/modules/footer/footer.module.scss';

import { type Ideas_QueryFragment } from './query';
import { AnimatedIdeas, StaticIdeas } from './variants';

export interface IdeasProps {
  data: FragmentType<typeof Ideas_QueryFragment>;
}

export function Ideas({ data }: IdeasProps) {
  const prefersReducedMotion = useReducedMotion();
  const hasCursor = useHasCursor();

  return (
    <nav>
      <div className={cx(typographyStyles.bodyLarge, styles.title)}>Explore Ideas</div>
      {hasCursor && !prefersReducedMotion ? (
        <AnimatedIdeas ideas={data} />
      ) : (
        <StaticIdeas ideas={data} />
      )}
    </nav>
  );
}
