'use client';

import { AnalyticsProvider } from '~/ui/components/analytics/context';
import { BreadcrumbProvider } from '~/v1/system/breadcrumb/context';

export interface ProvidersProps {
  children?: React.ReactNode;
}

export function Providers({ children }: ProvidersProps) {
  return (
    <AnalyticsProvider>
      <BreadcrumbProvider>{children}</BreadcrumbProvider>
    </AnalyticsProvider>
  );
}
