import { useState } from 'react';

import cx from 'classnames';
import { AnimatePresence, motion } from 'motion/react';

import { type FragmentType, getFragmentData } from '~/contentful/graphql';
import { flattenCollection, flattenSingletonCollection } from '~/contentful/utils/collection';
import { Event, trackEvent } from '~/ui/components/analytics';
import { Image } from '~/ui/modules/image';
import { type Image_AssetFragment } from '~/ui/modules/image/query';
import { Breakpoint } from '~/ui/styles/grid';
import typographyStyles from '~/ui/styles/typography.module.scss';
import { getInternalLinkFromFragment } from '~/ui/systems/internalLink';
import { Link } from '~/v1/components/link/link';

import { SecondaryNavigationContent_QueryFragment } from './query';
import { type NavigationItem } from '../../navigation';
import styles from '../secondary.module.scss';

interface NavigationItemData {
  label: string;
  href: string;
  image?: FragmentType<typeof Image_AssetFragment>;
}

type SecondaryNavigationContentProps = {
  data: FragmentType<typeof SecondaryNavigationContent_QueryFragment>;
  activeNavigation: NavigationItem | undefined;
  isDesktop?: boolean;
};

const MotionLink = motion.create(Link);

export const SecondaryNavigationContent: React.FC<SecondaryNavigationContentProps> = ({
  data,
  activeNavigation,
  isDesktop,
}) => {
  const [activeSecondary, setActiveSecondary] = useState<NavigationItemData | null>(null);
  const statementClasses = cx(styles.statement, typographyStyles.blockquote, {
    [styles.statementFaded]: activeSecondary,
  });

  const { mainNavigationCollection } = getFragmentData(
    SecondaryNavigationContent_QueryFragment,
    data,
  );

  const navigation = flattenSingletonCollection(mainNavigationCollection);

  let activeNavigationItems: NavigationItemData[] | undefined = undefined;
  switch (activeNavigation) {
    case 'ideas':
      const ideas = flattenCollection(navigation.ideasCollection);
      activeNavigationItems = ideas.map(idea => ({
        label: idea.name ?? '',
        href: getInternalLinkFromFragment(idea),
        image: idea.navigationImagePortrait ?? undefined,
      }));
      break;

    case 'pages':
      const pages = flattenCollection(navigation.pagesCollection);
      activeNavigationItems = pages.map(page => ({
        label: page.title ?? '',
        href: getInternalLinkFromFragment(page),
        image: page.navigationImagePortrait ?? undefined,
      }));
      break;

    case 'search':
    default:
      break;
  }

  const additionalDelay = isDesktop && !activeNavigation ? 0.4 : 0;

  return (
    <div className={styles.contentInner}>
      <div className={styles.itemsWrapper}>
        <AnimatePresence>
          {activeNavigationItems?.map(
            (item, index) =>
              item && (
                <MotionLink
                  className={styles.secondaryNavigationItem}
                  key={`${item.label}-${index}`}
                  initial={{ opacity: 0, transform: 'translateX(-10px)' }}
                  animate={{ opacity: 1, transform: 'translateX(0px)' }}
                  exit={{
                    opacity: 0,
                    transform: 'translateX(-10px)',
                    transition: {
                      duration: 0,
                    },
                  }}
                  transition={{ duration: 0.5, delay: additionalDelay + index * 0.05 }}
                  onMouseEnter={() => setActiveSecondary(item)}
                  onMouseLeave={() => setActiveSecondary(null)}
                  href={{
                    pathname: item.href,
                    query: { follow: false },
                  }}
                  onClick={() =>
                    trackEvent(Event.NAVIGATION_SECONDARY, {
                      navigationCategory: activeNavigation,
                      navigationItem: item.label,
                      navigationLink: item.href,
                    })
                  }
                >
                  {item.image && (
                    <Image
                      image={item.image}
                      className={cx(styles.secondaryNavigationItemImage, styles.linkImage)}
                      fill={false}
                      width={48}
                      height={64}
                      aspectRatio="2/3"
                    />
                  )}
                  {item.label}
                </MotionLink>
              ),
          )}
        </AnimatePresence>
      </div>
      <div className={styles.contentRight}>
        <AnimatePresence>
          {activeSecondary && activeSecondary.image && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{
                opacity: 0,
                transition: {
                  duration: 0.3,
                },
              }}
              transition={{ duration: 0.5, ease: [0.12, 0, 0.39, 0] }}
              className={styles.secondaryNavigationItemImage}
            >
              <Image
                image={activeSecondary.image}
                priority
                colSpan={{
                  [Breakpoint.SM]: 4,
                  [Breakpoint.MD]: 7,
                }}
                aspectRatio="2/3"
              />
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {navigation.babyMissionStatement && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{
                opacity: 0,
                transition: {
                  duration: 0.3,
                  delay: 0,
                },
              }}
              transition={{
                duration: 0.5,
                delay: 0.3,
              }}
              className={styles.statementWrapper}
            >
              <p className={statementClasses}>{navigation.babyMissionStatement}</p>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};
