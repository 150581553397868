import(/* webpackMode: "eager" */ "/home/runner/work/mellonorg-webapp/mellonorg-webapp/app/layout.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mellonorg-webapp/mellonorg-webapp/app/globals.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/home/runner/work/mellonorg-webapp/mellonorg-webapp/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VariableManager"] */ "/home/runner/work/mellonorg-webapp/mellonorg-webapp/app/variableManager.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SimulatePreloadedQuery"] */ "/home/runner/work/mellonorg-webapp/mellonorg-webapp/node_modules/@apollo/client-react-streaming/dist/index.cc.js");
;
import(/* webpackMode: "eager", webpackExports: ["Role"] */ "/home/runner/work/mellonorg-webapp/mellonorg-webapp/node_modules/@ariakit/react/esm/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mellonorg-webapp/mellonorg-webapp/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mellonorg-webapp/mellonorg-webapp/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mellonorg-webapp/mellonorg-webapp/src/ui/assets/icons/facebook.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mellonorg-webapp/mellonorg-webapp/src/ui/assets/icons/instagram.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mellonorg-webapp/mellonorg-webapp/src/ui/assets/icons/line.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mellonorg-webapp/mellonorg-webapp/src/ui/assets/icons/linkedin.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mellonorg-webapp/mellonorg-webapp/src/ui/assets/icons/threads.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mellonorg-webapp/mellonorg-webapp/src/ui/assets/icons/youtube.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mellonorg-webapp/mellonorg-webapp/src/ui/components/grid/column/column.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mellonorg-webapp/mellonorg-webapp/src/ui/components/grid/context.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mellonorg-webapp/mellonorg-webapp/src/ui/components/grid/grid.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mellonorg-webapp/mellonorg-webapp/src/ui/components/grid/guidelines/guidelines.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mellonorg-webapp/mellonorg-webapp/src/ui/modules/layout/footer/footer.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mellonorg-webapp/mellonorg-webapp/src/ui/modules/layout/footer/ideas/ideas.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mellonorg-webapp/mellonorg-webapp/src/ui/modules/layout/navigation/navigation.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mellonorg-webapp/mellonorg-webapp/src/ui/modules/newsletter/newsletter.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mellonorg-webapp/mellonorg-webapp/src/ui/styles/grid.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mellonorg-webapp/mellonorg-webapp/src/ui/modules/socials/socials.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mellonorg-webapp/mellonorg-webapp/src/v1/components/link/link.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mellonorg-webapp/mellonorg-webapp/src/v1/components/navigation/placeholder/placeholder.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["FooterLogo"] */ "/home/runner/work/mellonorg-webapp/mellonorg-webapp/src/v1/modules/footer/animation/animation.tsx");
