'use client';

import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

import { useIsomorphicLayoutEffect } from 'framer-motion';
import Cookies from 'js-cookie';

import { Route } from '~/v1/constants/route';
import { StorageKey } from '~/v1/constants/storageKey';
import { type Theme } from '~/v1/system/theme/theme.manager';

export interface VariableManagerProps {
  theme: Theme;
}

export function VariableManager({ theme }: VariableManagerProps) {
  const pathname = usePathname();

  // TODO: This reruns on every navigation. As a followup, this logic should be
  //  moved into the layout component and only run on render
  useEffect(() => {
    if (pathname !== Route.HOME) {
      Cookies.set(StorageKey.INTRO_ANIMATION, theme.primary, { expires: 1 });
    }
  }, [theme.primary, pathname]);

  useIsomorphicLayoutEffect(() => {
    const onResize = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    // onResize should run once on mount
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return null;
}
