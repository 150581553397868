import { graphql } from '~/contentful/graphql';

export const Ideas_QueryFragment = graphql(`
  fragment Ideas_QueryFragment on Query {
    ideaCollection {
      items {
        ...Idea_IdeaFragment
      }
    }
  }
`);
