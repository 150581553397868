import { motion } from 'framer-motion';

import { type FragmentType, getFragmentData } from '~/contentful/graphql';
import { flattenSingletonCollection } from '~/contentful/utils/collection';
import { filterNonOptional } from '~/contentful/utils/optional';
import { SecondaryNavigationSearch_QueryFragment } from '~/ui/modules/layout/navigation/secondary/search/query';
import { Search } from '~/v1/components/search/search';
import { SearchGtmType } from '~/v1/components/search/search.interface';
import { SearchManager } from '~/v1/components/search/search.manager';
import { SearchSuggestion } from '~/v1/components/search/suggestions/suggestion/suggestion';
import { Route } from '~/v1/constants/route';
import { getSuggestions } from '~/v1/graphql/actions/client/getSuggestions';

import styles from '../secondary.module.scss';

interface SecondaryNavigationSearchProps extends React.HTMLAttributes<HTMLInputElement> {
  data: FragmentType<typeof SecondaryNavigationSearch_QueryFragment>;
}

export const SecondaryNavigationSearch: React.FC<SecondaryNavigationSearchProps> = ({
  data,
  ...props
}) => {
  const { searchPageCollection } = getFragmentData(SecondaryNavigationSearch_QueryFragment, data);
  const searchSuggestions = filterNonOptional(
    flattenSingletonCollection(searchPageCollection).featuredSearches,
  );

  return (
    <motion.div
      className={styles.searchWrapper}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{
        opacity: 0,
        transition: {
          duration: 0,
        },
      }}
      transition={{ delay: 0.2, duration: 0.5, ease: [0.12, 0, 0.39, 0] }}
    >
      <SearchManager
        isGlobal
        gtm={SearchGtmType.GLOBAL}
        api={getSuggestions}
        redirectTo={Route.SEARCH}
      >
        <Search isThemed {...props}>
          {searchSuggestions && (
            <SearchSuggestion label="Featured searches" suggestions={searchSuggestions} />
          )}
        </Search>
      </SearchManager>
    </motion.div>
  );
};
