import { graphql } from '~/contentful/graphql';

export const SecondaryNavigationContent_QueryFragment = graphql(`
  fragment SecondaryNavigationContent_QueryFragment on Query {
    mainNavigationCollection(limit: 1, order: sys_publishedAt_DESC) {
      items {
        babyMissionStatement
        ideasCollection {
          items {
            ...InternalLink_EntryFragment
            name
            navigationImagePortrait {
              ...Image_AssetFragment
            }
          }
        }
        pagesCollection {
          items {
            ...InternalLink_EntryFragment

            ... on EventsPage {
              title
              navigationImagePortrait {
                ...Image_AssetFragment
              }
            }

            ... on FinancialsPage {
              title
              navigationImagePortrait {
                ...Image_AssetFragment
              }
            }

            ... on GrantmakingAreasPage {
              title
              navigationImagePortrait {
                ...Image_AssetFragment
              }
            }

            ... on HistoryPage {
              title
              navigationImagePortrait {
                ...Image_AssetFragment
              }
            }

            ... on MissionPage {
              title
              navigationImagePortrait {
                ...Image_AssetFragment
              }
            }

            ... on NewsPage {
              title
              navigationImagePortrait {
                ...Image_AssetFragment
              }
            }

            ... on PeoplePage {
              title
              navigationImagePortrait {
                ...Image_AssetFragment
              }
            }
          }
        }
      }
    }
  }
`);
