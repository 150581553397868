import cx from 'classnames';

import { type Icon } from '~/ui/assets/icons/types';
import { Event, trackEvent } from '~/ui/components/analytics';
import { type NavigationItem } from '~/ui/modules/layout/navigation';
import { getLabelForNavItem } from '~/v1/components/navigation/navigation.utils';

import styles from './primary.module.scss';

export interface PrimaryNavigationItemProps {
  item: NavigationItem;
  setActiveNavigation: (navigationItem: NavigationItem | undefined) => void;
  activeNavigation: NavigationItem | undefined;
  icon?: Icon;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export function PrimaryNavigationItem({
  item,
  activeNavigation,
  setActiveNavigation,
  icon: Icon,
  onClick,
}: PrimaryNavigationItemProps) {
  const label = getLabelForNavItem(item);

  return (
    <button
      className={cx(styles.navigationItem, {
        [styles.navigationItemActive]: item === activeNavigation,
      })}
      onClick={event => {
        if (item === activeNavigation) {
          setActiveNavigation(undefined);
          return;
        }
        trackEvent(Event.NAVIGATION_PRIMARY, {
          navigationCategory: label,
        });
        setActiveNavigation(item);
        onClick?.(event);
      }}
      aria-expanded={item === activeNavigation}
    >
      {label}
      {Icon && <Icon className={styles.icon} aria-hidden />}
    </button>
  );
}
